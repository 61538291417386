import "./App.css";
import Navigation from "./Nav";
import Hero from "./Hero";
import About from "./About";
import Event from "./Event";
import Contact from "./Contact";

function App() {
  return (
    <>
      <Navigation />
      <Hero />
      <About />
      <Event />
      <Contact />
    </>
  );
}

export default App;
